import Homepage from './component/Homepage';
import Routing from './Routing/Routingfile';

function App() {
  return (
  //  <Homepage />
   <Routing />
  );
}

export default App;
